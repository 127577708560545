<template>
  <div class="group-message-container">
    <!-- 切换区域 -->
    <ul class="toggle-box">
      <li
        v-for="(item, index) in toggleList"
        :key="item.id"
        :class="{ 'li-select': currentIndex === index }"
        @click="toggleIndex(index)"
      >{{ item.title }}</li>
    </ul>

    <!-- 按钮区域 -->
    <div class="btn-box">
      <el-date-picker
        v-model="queryInfo.selectDate"
        type="month"
        :placeholder="$t('ChooseDate')"
        value-format="yyyy-MM"
        @change="getMessageList"
      >
      </el-date-picker>
      <el-button
        icon="el-icon-message btn-icon"
        @click="sendMessage(currentIndex)"
        :disabled="right<3"
      >{{ currentIndex === 0 ? $t('GroupMessage') : $t('HairpinMessage') }}</el-button>
    </div>

    <!-- 列表区域 -->
    <div
      v-if="messageList.length"
      class="message-list"
    >
      <div
        v-for="item in messageList"
        :key="item.id"
        class="message-item"
      >
        <!-- 顶部区域 -->
        <div class="item-top-box">
          <div class="left-box">
            <h3>{{ item.title }}</h3>
            <p class="content">{{ item.content }}</p>
          </div>
          <div class="right-box">
            <!--<span @click="editMessage(item, currentIndex)">{{ currentIndex === 0 ? $t('EditSend') : $t('AnewSend') }}</span>-->
            <span @click="editMessage(item, currentIndex)">{{$t('EditSend')}}</span>
            <span @click="revocationMessage(item)">{{ $t('RevokeReport') }}</span>
          </div>
        </div>

        <!-- 中间区域 -->
        <div class="item-middle-box">
          <template v-if="currentIndex === 0">
            <div
              v-if="item.isCheckAll === 1"
              class="public-box"
            >{{ $t('Publicity') }}</div>
            <div
              v-else
              class="left-box"
            >
              <span>{{ $t('Visiable') }}：</span>
              <span class="group-name">{{ item.visibleGroup }}</span>
            </div>
          </template>
          <template v-else>
            <div class="left-box">
              <span>{{ $t('Visiable') }}：</span>
              <span class="group-name">{{ item.visibleGroup }}</span>
            </div>
          </template>
          <u
            class="right-box"
            @click="showReadFulltext(item)"
          >{{ $t('ReadMore') }}</u>
        </div>

        <!-- 底部区域 -->
        <div class="item-bottom-box">
          <div class="publisher">
            <span>{{ currentIndex === 0 ? $t('PublisherName'): $t('AdministratorName') }}：</span>
            <span>{{ item.issueName }}</span>
          </div>
          <div class="create-date">
            <span>{{ $t('CreationDate') }}：</span>
            <span>{{ item.sendTime }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 无数据 -->
    <div
      v-else
      class="no-data"
    >
      <img src="@/assets/img/no_data.png" />
      <p>{{ $t('NoData') }}</p>
    </div>

    <!-- 分页区域 -->
    <div
      v-if="messageList.length"
      class="pagination-box"
    >
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        layout="prev, pager, next"
        :page-size="queryInfo.pageSize"
        :total="queryInfo.total"
        style="margin: 10px auto 10px 20px"
      >
      </el-pagination>
    </div>

    <!-- 阅读全文对话框 -->
    <ReadFulltext
      :show.sync="readFulltext.show"
      :data="readFulltext"
      v-if="readFulltext.show"
    ></ReadFulltext>
  </div>
</template>

<script>
import { deepCopy } from "@/utils/tool.js";
import ReadFulltext from "./dialog/ReadFulltext.vue";
import api from "@/fetch/api";

export default {
  name: "GroupMessage",
  components: {
    ReadFulltext,
  },
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      right: Number(data.messagePermission),
      // 切换数据
      toggleList: [
        {
          id: 1,
          title: this.$t("GroupMessage"),
        },
        {
          id: 2,
          title: this.$t("HairpinMessage"),
        },
      ],
      currentIndex: Number(sessionStorage.getItem("messageCurrentIndex")) || 0, // 0:群发、1:私发

      // 筛选参数
      queryInfo: {
        page: 1,
        pageSize: 5,
        total: 0,
        selectDate: "", // 选择日期
      },
      messageList: [], // 消息列表

      // 阅读全文对话框
      readFulltext: {
        show: false,
        data: {},
      },
    };
  },
  created() {
    this.getMessageList();
  },
  methods: {
    // 获取消息列表
    getMessageList() {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
        "original-text": "non",
      };
      const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
      const params = {
        chamberId: data.chamberId,
        page: this.queryInfo.page,
        messageType: this.currentIndex === 0 ? 1 : 2, // 1:群发、2:私发
        language: languageObj[locale],
      };
      if (this.queryInfo.selectDate) params.date = this.queryInfo.selectDate;

      api.getBatchMessagesNew(params).then((res) => {
        const copyData = deepCopy(res.data.data.pageList);
        if (copyData.length) {
          copyData.forEach((item) => {
            item.visibleGroup = this.handleGroup(item.visibleList);
          });
        }
        this.messageList = copyData;
        this.queryInfo.total = res.data.data.total;
      });
    },

    // 处理可见分组
    handleGroup(arr) {
      if (arr.length) {
        const groupName = [];
        arr.forEach((item) => {
          groupName.push(item.groupName);
        });
        return groupName.join(",");
      } else {
        return "-";
      }
    },

    // 分页
    handleCurrentChange(page) {
      this.queryInfo.page = page;
      this.getMessageList();
    },

    // 切换
    toggleIndex(index) {
      this.currentIndex = index;
      sessionStorage.setItem("messageCurrentIndex", index);
      this.getMessageList();
    },

    // 群发消息、私发消息
    sendMessage(index) {
      if (index === 0) {
        this.$router.push({ name: "MassMessage", query: { type: "send" } });
      } else if (index === 1) {
        this.$router.push({ name: "PrivateMessage", query: { type: "send" } });
      }
    },

    // 编辑并重新发送
    editMessage(row, currentIndex) {
      if(this.right<2){
        this.$message.error(this.$t('NoRight'))
      }else{
        if (currentIndex === 0) {
          this.$router.push({
            name: "MassMessage",
            query: { type: "edit", id: row.id },
          });
        } else {
          this.$router.push({
            name: "PrivateMessage",
            query: { type: "edit", id: row.id },
          });
        }
      }
    },

    // 撤回
    revocationMessage(row) {
      if(this.right<3){
        this.$message.error(this.$t('NoRight'))
      }else{
        this.$confirm(this.$t("RevocationTip"), this.$t("Attention"), {
          confirmButtonText: this.$t("VerifyRevocation"),
          cancelButtonText: this.$t("CancelRevocation"),
          type: "warning",
        })
            .then(() => {
              api
                  .withdrawMessage({
                    messageId: row.id,
                  })
                  .then(() => {
                    this.getMessageList();
                    this.$message.success({
                      message: this.$t("RevocationSuccess"),
                      duration: 2000,
                    });
                  });
            })
            .catch(() => {});
      }
    },

    // 显示阅读全文对话框
    showReadFulltext(row) {
      this.readFulltext.show = true;
      this.readFulltext.data = row;
    },
  },
};
</script>

<style lang="scss" scoped>
.group-message-container {
  width: 1600px;
  ul,
  li {
    list-style: none;
  }

  // 切换区域
  .toggle-box {
    display: flex;
    border: 1px solid #a6a6a6;
    border-radius: 3px;
    & > li {
      flex: 1;
      line-height: 50px;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
    }
    // 选中样式
    & > .li-select {
      background-color: #425d7b;
      color: white;
    }
  }

  // 按钮区域
  .btn-box {
    text-align: right;
    margin: 20px 0;
    ::v-deep .el-button {
      margin-left: 20px;
      .btn-icon {
        margin-right: 3px;
      }
    }
  }

  // 列表区域
  .message-list {
    .message-item {
      background-color: #d9ebff;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 5px;

      // 顶部区域
      .item-top-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > .left-box {
          flex: 1;
          margin-right: 30px;
          & > h3 {
            margin-bottom: 20px;
          }
          & > .content {
            font-size: 14px;
          }
        }
        & > .right-box {
          display: flex;
          flex-direction: column;
          & > span {
            text-align: center;
            padding: 5px 10px;
            color: #c61516;
            font-size: 12px;
            border: 1px solid #8b949e;
            border-radius: 3px;
            cursor: pointer;
          }
          & > span:first-child {
            margin-bottom: 10px;
          }
        }
      }

      // 中间区域
      .item-middle-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
        font-size: 14px;
        & > .left-box {
          flex: 1;
          display: flex;
          margin-right: 50px;
          & > .group-name {
            flex: 1;
          }
        }
        & > .right-box {
          cursor: pointer;
        }
      }

      // 底部区域
      .item-bottom-box {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        & > .publisher {
          margin-right: 50px;
        }
      }
    }
    .message-item:last-child {
      margin-bottom: 0;
    }
  }

  // 无数据
  .no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
    p {
      margin-top: 15px;
      color: #bbbbbb;
    }
  }
}
</style>
<style lang="scss">
.el-message-box__header,
.el-message-box__btns {
  text-align: center;
}
</style>
